import {forgotpass} from './../../services/services';

export const FORGOTPASS_SUCCESS = 'FORGOTPASS_SUCCESS';
export const FORGOTPASS_ERROR = 'FORGOTPASS_ERROR';

export function forgotpassword(userdata){ 
    return (dispatch) =>
        forgotpass(userdata).then((response)=>{ 
            if(response.status===200){
                return dispatch({
                    type   : FORGOTPASS_SUCCESS,
                    payload: response.data,
                });
            }
            else{ 
                return dispatch({
                    type   : FORGOTPASS_ERROR,
                    payload: { message: 'Error' },
                });
            }
        })
}