import * as Actions from '../actions';

const initialState = {
    success: false,
    mycoursedata:'',
};

const mycourse = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.MYCOURSE_SUCCESS:
        {
            return {
                ...state,
                success: true,
                mycoursedata  : action.payload
            };
        }
        default:
        {
            return state
        }
    }
};

export default mycourse;