import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import './../../../../node_modules/simplebar/dist/simplebar.css'
import toaster from 'toasted-notes';
import './../../../../node_modules/toasted-notes/src/styles.css'
import LinesEllipsis from 'react-lines-ellipsis';
import HeaderPage from './../../common/HeaderPage';
import FooterPage from './../../common/FooterPage';
import * as Actions from './../../../store/actions';
import * as config from './../../../config';
import ShowcoursePage from './ShowcoursePage';

class MycourseComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myCourses: [],
      showCourseList:true,
      course:'',
      showProgressbar:true,
      courseIsOpen:false,
    }
    this.access_token = localStorage.getItem('access_token');
    this.usertype = localStorage.getItem("usertype");
  }

  componentDidMount() {
    
    if(this.access_token && this.usertype){
        this.props.getCourses(this.access_token, {
          'user_type': this.usertype
        })
      }
    
  }

  componentWillReceiveProps = (nextProps) => {
    const result = nextProps.mycourse;
    if (result.success === true) {
      if (result.mycoursedata.status_code === 200) {
        this.setState({ myCourses: result.mycoursedata.courses[0], showProgressbar:false})
      }
    }
  }

  
  onClickStartcourse = (course) => {
    //history.push('/showcourse/' + id);
    this.setState({showCourseList:false, course:course})
   // this.setState({courseIsOpen:true, course:course})
  }

  tostarNotification() {
    toaster.notify(<div className="row toast-notification">
      <div className="col-sm-2 col-3  toast-notification-playicon"><i class="la la-play-circle"></i></div>

      <div className="col-sm-9 col-8  toast-notification-text"> <span>My custom toaster</span>
        <LinesEllipsis
          text='Donec rhoncus lobortis eros. Etiam laoreet cursus mi quis ultricies. Nulla sem tortor, tempor pellentesque augue quis.Donec rhoncus lobortis eros. Etiam laoreet cursus mi quis ultricies. Nulla sem tortor, tempor pellentesque augue quis.  Nulla sem tortor, tempor pellentesque augue quis. Nulla sem tortor, tempor pellentesque augue quis. DDDD'
          maxLine='1'
          ellipsis='...'
          trimRight
          basedOn='letters'
        />
      </div></div>, {
        duration: 4000
      })
  }

  closeCourseModal = () =>{
    this.setState({courseIsOpen: false});
    window.location.reload();
  }

  render() {
    const imgurl = config.mediaPath['courseimage'];

    return (
      
      <div>
        {this.state.showCourseList ? 
          <div>
            <HeaderPage />
            <div className="container course-container" >

              <h2 className="pagetitle">My Courses</h2>
                {this.state.showProgressbar ? <div className="loader" style={{left:'50%'}}>Loading...</div> :
                  this.state.myCourses ? 
                      <div>
                        <div className="row">
                          <div className="col-xl-3 col-md-3">
                            <img src={imgurl.filePath+this.state.myCourses.course_image} className="courseimg" alt="courseimg" />
                          </div>
                          <div className="col-xl-5 col-md-5 course-dec">
                            <span className="course-title">{this.state.myCourses.course_name}</span>
                            {this.state.myCourses.course_description}
                          </div>
                          <div className="col-xl-4 col-md-4 startcourse-hold ">
                            <button type="button" onClick={() => { this.onClickStartcourse(this.state.myCourses) }} className="btn btn-primary startcourse">Start Course</button>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-12 separator " ></div>
                        </div>
                      </div>
                  
                :<div>
                      <div className="row ">
                      <span className="nofound">No result found</span>
                      </div> 
                  </div>
              }
            </div>
            <FooterPage />
          </div>
        :<ShowcoursePage
           Course={this.state.course}>
           </ShowcoursePage>}
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getCourses: Actions.getCourses
  }, dispatch)
}

function mapStateToProps({ mycourse }) {
  return {
    mycourse: mycourse
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MycourseComponent));

