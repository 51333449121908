import React,{Component} from 'react';   
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import * as Actions from '../../store/actions';
import logo from './../../images/logo.svg';
import logonew from './../../images/logonew.svg';
import regimg from './../../images/reg.png';
import history from './../../history';
import * as config from './../../config';
import Message from './../common/Messages';
import { Tooltip } from 'reactstrap';
import {emailValidation, nicknameValidate, genderValidate, stateValidate, cityValidate, passwordValidate, confPasswordValidate} from './../validations/validations';

class RegisterComponent extends Component{
    
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            nickname:'',
            gender:'',
            email:'',
            password: '',
            confpassword: '',
            city:'',
            state:'',
            nicknameError:'', 
            genderError:'', 
            emailError:'', 
            passwordError:'', 
            confpasswordError:'', 
            cityError:'',
            stateError:'',
            states:[],
            showMessage:false,
            messageData:'',
            agency_name:'',
            others:'',
            agency_type:'',
            agency_type_disable:true,
            tooltipOpen: false
        }

       let url = this.props.match.url;
       //this.userType = url.slice(10); //get usertype from loginurl after '/register/'
       this.userType ='parents';
    }

    componentDidMount() 
    { 
        this.props.getState();
    }

    componentWillReceiveProps = (nextProps) => { 
        const states = nextProps.registerUser.statedata;
        const regdata = nextProps.registerUser.registerdata;
    
        if(states){
            this.setState({states:states.states})
        }
       
        if(regdata){
            if(regdata.status_code===401){
                this.setState({emailError:'Email Address already exists'});
                nextProps.registerUser.registerdata ='';
            }
            if(regdata.status_code===200){ //if successfully register then set access_token & userdata in localStorage for Auto login 
                localStorage.setItem('access_token', regdata.token);
                localStorage.setItem('userdata', JSON.stringify(regdata.userdata));
                this.setState({showMessage:true,
                                messageData:{type:'success',message:'You have successfully registered'},
                            });
                            nextProps.registerUser.registerdata ='';
            }
            
        }
    }

    OnPropertyChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value});
    }

    Onagency_typeChange = (e) =>{
        this.setState({agency_type:e.target.value},()=>{
            if(this.state.agency_type === "Others")
            {
                this.setState({agency_type_disable:false},() =>{})
            }
            else{
                this.setState({others:''})
                this.setState({agency_type_disable:true},() =>{})
            }
        })
    }

    validate = () => {
        let nicknameError = nicknameValidate(this.state.nickname);
        let genderError = genderValidate(this.state.gender);
        let emailError = emailValidation(this.state.email);
        let passwordError = passwordValidate(this.state.password);
        let confpasswordError = confPasswordValidate(this.state.confpassword,this.state.password);
        let stateError = stateValidate(this.state.state);   
        let cityError = cityValidate(this.state.city);

        if(nicknameError || genderError || emailError || passwordError || confpasswordError || stateError || cityError){
            this.setState({ nicknameError, genderError, emailError, passwordError, confpasswordError, stateError, cityError});
            return false;
        }

        return true;
    }

    onClickSubmit = () => {
        const isValid = this.validate();
        if(isValid){
            this.setState({nicknameError:'',genderError:'', emailError:'', passwordError:'', confpasswordError:'', stateError:'', cityError:''})
            var user = {
                nickname:this.state.nickname,
                gender:this.state.gender,
                email:this.state.email,
                password:this.state.password,
                password_confirmation:this.state.confpassword,
                state:this.state.state,
                city:this.state.city,
                user_type:this.userType,
                agency_name:this.state.agency_name,
                agency_type:this.state.others === "" ? this.state.agency_type : this.state.others
            };
           this.props.submitRegister(user);
        }
    }

    onClickCancel = () => {
        const path  = config.userTypeRoutes[this.userType]
        if (path) {
            history.push(path.loginPath)
        }
    }

    onClickLogo = () =>{  
        const path  = config.userTypeRoutes[this.userType]
        if (path) {
            history.push(path.loginPath)
        }
    }

    closeShowMessage = () =>{
        this.setState({showMessage: false})
        const path  = config.userTypeRoutes[this.userType]
        if (path) {
            history.push(path.loginPath)
        }
        window.location.reload();
    }

    

    toggle() {
        this.setState({
          tooltipOpen: !this.state.tooltipOpen
        });
      }


    render(){
        const {nickname,email,password,confpassword,city,agency_name,others,agency_type_disable} = this.state;

        return(
            <div>
              
            <div className="container-hold">        
                <nav className={localStorage.getItem("usertype") === "youth" ? "navbar navbar-expand navbar-gradient": "navbar navbar-expand navbar-gradientnew"}>
                    <div className="container">
                        <div className="navbar-brand" > 
                            <div className="logoaction" onClick={this.onClickLogo} ></div>
                            <img src={localStorage.getItem("usertype") === "youth" ? logo : logonew}  className="logo" alt="logo"/>
                        </div>
                    </div>
                </nav>
            </div>

            <div className="container container-reg">
                <div className="reg-form-mrg">
                    <div className="row">
                        <div className="col-xl-5 col-md-5  regimg-hold"><h2 className="pagetitle">Register</h2><img src={regimg }  className="regimg" alt="regimg"/></div>
                            <div className="col-xl-7 col-md-7 editlabel">
                                <div className="row"> 
                                    <div className="col-xl-12">
                                        <div className="form-group">
                                            <label htmlFor="email ">Email Address</label>
                                            <input type="text" className={ this.state.emailError ? 'form-control errorborder'  : 'form-control' }  value={email}
                                                name="email"  onChange={this.OnPropertyChange}/>
                                            <div style={{color:'red'}} className="reg-error" >{this.state.emailError}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row"> 
                                    <div className="col-xl-6">
                                        <div className="form-group">
                                            <label htmlFor="nname ">Nickname </label>
                                            <input type="text" className={ this.state.nicknameError ? 'form-control errorborder'  : 'form-control' } value={nickname}
                                                name="nickname"  onChange={this.OnPropertyChange}/>
                                            <div style={{color:'red'}} className="reg-error" >{this.state.nicknameError}</div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="form-group">
                                            <label htmlFor="gender "> Gender</label>
                                            <div>
                                                 <select className={ this.state.genderError ? 'form-control errorborder'  : 'form-control' } name="gender" onChange={this.OnPropertyChange}>
                                                    <option value="">Select</option>
                                                    <option value="M">Male</option>
                                                    <option value="F">Female</option>
                                                    <option value="O">Other</option>
                                                </select>
                                            </div>
                                            <div style={{color:'red'}} className="reg-error" >{this.state.genderError}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row"> 
                                    <div className="col-xl-6">
                                        <div className="form-group">
                                            <label htmlFor="state">State</label>
                                            <select className={ this.state.stateError ? 'form-control errorborder'  : 'form-control' } name="state" onChange={this.OnPropertyChange}>
                                                    <option value="">Select</option>
                                                     {this.state.states.map(state =>{ return <option key={state.name} value={state.name}>{state.name}</option>})}
                                            </select>
                                           <div style={{color:'red'}} className="reg-error" >{this.state.stateError}</div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="form-group">
                                            <label htmlFor="city"> City</label>
                                            <input type="text" className={ this.state.cityError ? 'form-control errorborder'  : 'form-control' } value={city}
                                                name="city"  onChange={this.OnPropertyChange}/>
                                            <div style={{color:'red'}} className="reg-error" >{this.state.cityError}</div>
                                        </div>
                                    </div>
                                </div> 
                                <div className="row"> 
                                    <div className="col-xl-12">
                                        <div className="form-group">
                                            <label htmlFor="agency_name">Organization/School Name</label>
                                            <input type="text" className= 'form-control'  value={agency_name}
                                                name="agency_name"  onChange={this.OnPropertyChange}/>
                                            <div style={{color:'red'}} className="reg-error" ></div>

                                        </div>
                                    </div>
                                </div> 

                                <div className="row"> 
                                    <div className="col-xl-6">
                                        <div className="form-group">
                                            <label htmlFor="profession">Profession</label>
                                            <select className='form-control'  name="agency_type" onChange={this.Onagency_typeChange}>
                                                    <option value="">Select</option>
                                                    <option value="School Professional">School Professional</option>
                                                    <option value="Agency Professional">Agency Professional</option>
                                                    <option value="Healthcare Professional">Healthcare Professional</option>
                                                    <option value="Parent or Guardian">Parent or Guardian</option>
                                                    <option value="Student">Student</option>
                                                    <option value="Agency Volunteer">Agency Volunteer</option>
                                                    <option value="Others">Others</option>
                                            </select>
                                            <div style={{color:'red'}} className="reg-error" ></div>

                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="form-group">
                                            <label htmlFor="others"> Please Specify</label>
                                            <input type="text" className='form-control' value={others}
                                                name="others"  onChange={this.OnPropertyChange} disabled={agency_type_disable}/>
                                            <div style={{color:'red'}} className="reg-error" ></div>

                                        </div>
                                    </div>
                                </div>


                                <div className="row"> 
                                    <div className="col-xl-6">
                                        <div className="form-group">
                                            <label htmlFor="pass"> Password</label> <i className="la la-info-circle password-info" data-toggle="popover" title="" data-content="And here's some amazing content. It's very engaging. Right?" id="passTooltip"></i>
                                            <input type="password" className={ this.state.passwordError ? 'form-control errorborder'  : 'form-control' } value={password}
                                                name="password"  onChange={this.OnPropertyChange}/>

                                    <Tooltip placement={'right'} isOpen={this.state.tooltipOpen} target={'passTooltip'} toggle={this.toggle} style={{zIndex:'1000',background:'#fff',color:'#495057',fontSize:'15px',border:'1px solid #ced4da'}}>
                                        Password should be minimum 6 and maximum 18 characters long.
                                    </Tooltip>

                                            <div style={{color:'red'}} className="reg-error" >{this.state.passwordError}</div>
                                        </div>
                                    </div>
                                   
                                    <div className="col-xl-6">
                                        <div className="form-group">
                                            <label htmlFor="cpass">Confirm Password </label>
                                            <input type="password" className={ this.state.confpasswordError ? 'form-control errorborder'  : 'form-control' } value={confpassword}
                                                name="confpassword"  onChange={this.OnPropertyChange}/>
                                            <div style={{color:'red'}} className="reg-error" >{this.state.confpasswordError}</div>
                                            <div style={{color:'red'}} className="reg-error" ></div>
                                        </div>
                                    </div>
                                    
                                </div>

                                

                                <div className="row"> 
                                    <div className="col-xl-6">
                                        
                                    </div>

                                    <div className="col-xl-6">
                                        <div className="row">
                                            <div className="col-xl-6 col-6">   <input type="button"
                                                    value="CANCEL"
                                                    className="btn btn-outline-secondary reg-cancel "
                                                    onClick={this.onClickCancel}/>
                                            </div>
                                            <div className="col-xl-6 col-6">
                                                            <input type="button"
                                                                value="SUBMIT"
                                                                className="btn btn-primary reg-submit"
                                                                onClick={this.onClickSubmit} />
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                    </div>
                    
                </div>

            </div>

            <div className="footer">
                 Copyright © 2019. Connect 5. All rights reserved
            </div>

            {/*open message modal */} 
            {
                this.state.showMessage ? <Message
                    msgIsOpen = {this.state.showMessage}
                    msgIsClose = {this.closeShowMessage}
                    messageData = {this.state.messageData}
                ></Message> :''
            }


 </div>


        )
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        submitRegister:Actions.register,
        getState:Actions.getState
    }, dispatch)
}

function mapStateToProps({registerUser}) {
    return {
        registerUser:registerUser
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterComponent));

