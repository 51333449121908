import React, { Component } from 'react';   
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import Modal from 'react-awesome-modal';
import * as Actions from './../../store/actions';
import Message from './../common/Messages';
import { Tooltip } from 'reactstrap';
import {currPasswordValidate, newPasswordValidate, confPasswordValidate} from './../validations/validations';


class ChangepasswordComponent extends Component{
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            currpassword:'',
            newpassword:'',
            confpassword:'',
            currpasswordError:'',
            newpasswordError:'',
            confpasswordError:'',
            changepasserror:'',
            visible : false,
            forgotpasserror: '',
            forgotpasssuccess: '',
            showMessage:false,
            messageData:'',
            tooltipOpen: false
        };
        this.access_token = localStorage.getItem('access_token');
    }

    closeModal() {
        this.props.modalIsClose();
    }

    onPropertyChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    validate = () =>{
        let currpasswordError = currPasswordValidate(this.state.currpassword);
        let newpasswordError = newPasswordValidate(this.state.newpassword,this.state.currpassword)
        let confpasswordError = confPasswordValidate(this.state.confpassword,this.state.newpassword);
        
        if(currpasswordError || newpasswordError || confpasswordError ){
            this.setState({currpasswordError, newpasswordError, confpasswordError, changepasserror:''});
            return false;
        }

        return true;
    }

    onClickSubmit = () => { 
        const isValid = this.validate(); 
        if(isValid){
            var passdata = {
                old_password:this.state.currpassword,
                password:this.state.newpassword,
                password_confirmation:this.state.confpassword    
            };
            this.props.submitChangepass(passdata, this.access_token)
        }  
    }

    componentWillReceiveProps(nextProps){  
        if(nextProps.headerdata.success===false){
            this.setState({
                changepasserror: "You did not enter correct password",
                currpasswordError:'',
                newpasswordError:'',
                confpasswordError:'',
              });
        }
        if(nextProps.headerdata.success===true){
            this.setState({
                changepasserror:'',
                currpassword:'',
                newpassword:'',
                confpassword:'',
                currpasswordError:'',
                newpasswordError:'',
                confpasswordError:'',
                showMessage:true,
                messageData:{type:'success',message:"Password reset successfully"}
              });
        }
        
    }

    closeShowMessage = () =>{
        this.props.modalIsClose();
        this.setState({showMessage: false})
    }

    toggle() {
        this.setState({
          tooltipOpen: !this.state.tooltipOpen
        });
    }


    render() {
        const { currpassword, newpassword, confpassword } = this.state;

        return ( 
          <div>
            <Modal 
                visible={this.props.modalIsOpen} 
                width="500" 
                effect="fadeInDown"
            >
                <div>
                    <div className="modal-content" >
                        <div className="modal-header">
                            <h4 className="modal-title">Change Password</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={ event => this.closeModal()}>&times;</button>
                        </div>
                        
                        <div className="modal-body changepassword" >
                            <div className="col-xl-12" >
                                {(this.state.changepasserror ? <div style={{color:'red'}} className="reg-error" >{this.state.changepasserror}</div> : '')}
                                
                                <div className="form-group">
                                    <label htmlFor="pass"> Current Password</label> 
                                    <input type="password" className={ this.state.currpasswordError ? 'form-control errorborder'  : 'form-control' } value={currpassword}
                                        name="currpassword" onChange={this.onPropertyChange} />
                                    <div style={{color:'red'}} className="reg-error" >{this.state.currpasswordError}</div>
                                </div>
                                <div className="form-group" >
                                    <label htmlFor="pass"> New Password </label><i className="la la-info-circle password-info" id={'passTooltip'}></i>
                                    <Tooltip placement={'left'} isOpen={this.state.tooltipOpen} target={'passTooltip'} toggle={this.toggle} style={{zIndex:'1000'}}>
                                        Password should be minimum 6 and maximum 18 characters long.
                                    </Tooltip>
                                    <input type="password" className={ this.state.newpasswordError ? 'form-control errorborder'  : 'form-control' } value={newpassword}
                                        name="newpassword" onChange={this.onPropertyChange} />
                                    <div style={{color:'red'}} className="reg-error">{this.state.newpasswordError}</div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="pass"> Confirm New Password </label>
                                    <input type="password" className={ this.state.confpasswordError ? 'form-control errorborder'  : 'form-control' } value={confpassword}
                                        name="confpassword" onChange={this.onPropertyChange} />
                                    <div style={{color:'red'}} className="reg-error">{this.state.confpasswordError}</div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="modal-footer">
                            <input type="button" className="btn btn-outline-secondary margin-btn-left " value="CANCEL" onClick={ event => this.closeModal()}/>
                            <input type="button" className="btn btn-primary " value="SUBMIT" onClick={this.onClickSubmit}/>
                        </div>
                    </div>
                </div>
            </Modal>

            {/*open message modal */} 
            {
                this.state.showMessage ? <Message
                    msgIsOpen = {this.state.showMessage}
                    msgIsClose = {this.closeShowMessage}
                    messageData = {this.state.messageData}
                ></Message> :''
            }
          </div>
        );
      }
}

function mapDispatchToProps(dispatch)  {
    return bindActionCreators({
        submitChangepass:Actions.changepassword,
    }, dispatch)
}

function mapStateToProps ({ changePassword }) {
    return {
        headerdata:changePassword
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangepasswordComponent));

