import * as config from './../config';
const API_URL = config.API_URL;

export const scoid = (coruse, access_token) =>{ 
    var headers= {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access_token
    }

    let data = {
        method: "POST",
        body: JSON.stringify(coruse),
        headers: headers,
    }
    return  fetch(API_URL+"/api/auth/enroller", data)
        .then((response) => response.json())
        .then((responseJson) => { //console.log("responseJson :", responseJson.data.Enrollment_id)
            return responseJson.data.Enrollment_id;
    })
    .catch((error) => {
        return error;
    });
}

export const scormDataCount = (courseId, access_token) =>{ 
    var headers= {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access_token
    }

    let data = {
        method: "GET",
        headers: headers,
    }
    return  fetch(API_URL+'/api/auth/scorm_data_count?course_id='+courseId, data)
        .then((response) => response.json())
        .then((responseJson) => { 
            return responseJson.data.data;
    })
    .catch((error) => {
        return error;
    });
}

export const initializeElem = (courseId,variable,variablevalue,access_token) =>{ 
    var headers= {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access_token
    }

   let parameters={
       'course_id':courseId,
       'scormdata':{[variable] : variablevalue}
    }

    let data = {
        method: "POST",
        body: JSON.stringify(parameters),
        headers: headers,
    }
    return  fetch(API_URL+"/api/auth/scorm", data)
        .then((response) => response.json())
        .then((responseJson) => { 
            return responseJson;
    })
    .catch((error) => {
        return error;
    });
}

export const initializeCache = (courseId, access_token) =>{ 
    var headers= {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access_token
    }

    let data = {
        method: "GET",
        headers: headers,
    }
    return  fetch(API_URL+'/api/auth/get_scorm_data?course_id='+courseId, data)
        .then((response) => response.json())
        .then((responseJson) => { 
            return responseJson.data.data;
    })
    .catch((error) => {
        return error;
    });
}
