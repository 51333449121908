import {combineReducers} from 'redux';
import loginUser from './login.reducer';
import registerUser from './register.reducer';
import changePassword from './changepass.reducer';
import mycourse from './mycourse.reducer';

const appReducer = combineReducers({
    loginUser,
    registerUser,
    changePassword,
    mycourse
});

export default appReducer;


