import { country, city, state, userRegister, editUser, refreshToken } from './../../services/services';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const GETCOUNTRY_SUCCESS = 'GETCOUNTRY_SUCCESS';
export const GETCOUNTRY_ERROR = 'GETCOUNTRY_ERROR';
export const GETCITY_SUCCESS = 'GETCITY_SUCCESS';
export const GETCITY_ERROR = 'GETCITY_ERROR';
export const EDIT_SUCCESS = 'EDIT_SUCCESS';
export const GETSTATE_SUCCESS = 'GETSTATE_SUCCESS'; 
export const GETSTATE_ERROR = 'GETSTATE_ERROR';

export function register(user){ 
    return (dispatch) =>
        userRegister(user).then((response)=>{ 
            if(response.data.data){
                return dispatch({
                    type   : REGISTER_SUCCESS,
                    payload: response.data.data,
                });
            }
            else{ 
                return dispatch({
                    type   : REGISTER_ERROR,
                    payload: 'Error'
                });
            }
        })
}

export function getCountry(){ 
    return (dispatch) =>
        country().then((response)=>{
            if(response.data.data.status_code===201){
                return dispatch({
                    type   : GETCOUNTRY_SUCCESS,
                    payload: response.data.data
                });
            }
            else{ 
                return dispatch({
                    type   : GETCOUNTRY_ERROR,
                    payload: { message: 'Error' },
                });
            }
        })
}

export function getCity(){ 
    return (dispatch) =>
        city().then((response)=>{
            if(response.data.data.status_code===201){ 
                return dispatch({
                    type   : GETCITY_SUCCESS,
                    payload: response.data.data
                });
            }
            else{ 
                return dispatch({
                    type   : GETCITY_ERROR,
                    payload: { message: 'Error' },
                });
            }
        })
}

export function getState(){ 
    return (dispatch) =>
        state().then((response)=>{ 
            if(response.data.data.status_code===201){ 
                return dispatch({
                    type   : GETSTATE_SUCCESS,
                    payload: response.data.data
                });
            }
            else{ 
                return dispatch({
                    type   : GETSTATE_ERROR,
                    payload: { message: 'Error' },
                });
            }
        })
}

export function edit(user, access_token){
    return (dispatch) =>
        editUser(user, access_token).then((response)=>{ 
            if(response.error){ 
                if(response.error.status_code===401){  //call refreshToken when token experied for get refresh token and continue with editUser
                    refreshToken().then((res)=>{
                        return editUser(user,res.token)
                    }).then((resp) => {    
                        if(resp.data.status_code===200){  
                            return dispatch({
                                type   : EDIT_SUCCESS,
                                payload: resp.data.userdata
                            });
                        }
                    })
                }
            }
            else{ 
                if(response.data.status_code===200){  
                        return dispatch({
                            type   : EDIT_SUCCESS,
                            payload: response.data.userdata
                        });
                    }
            }
            
        })
}