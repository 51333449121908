
const charPattern = /^[a-zA-Z]+$/;

export const emailValidation = (email) =>{
    let emailError = '';
    const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line

    if(!email){
        emailError = 'Please enter Email Address';
        return emailError;
    }
    if(!emailPattern.test(email)){
        emailError = "Please enter valid Email Address";
        return emailError;
    }
}

export const nicknameValidate = (nickname) =>{
    let nicknameError='';
    if(!charPattern.test(nickname)){
        nicknameError = "Please enter valid Nickname";
    }
    return nicknameError;
}

export const currPasswordValidate = (currpassword) =>{
    let currpasswordError='';
    if(!currpassword){
        currpasswordError = "Please enter Current Password";
    }
    return currpasswordError;
}

export const newPasswordValidate = (newpassword,currpassword) =>{
    let newpasswordError='';
    if(newpassword===currpassword){
        newpasswordError = "Current Password and New Password is same";
    }
    if(newpassword.length<6 || newpassword.length>18){
        newpasswordError = "Please enter Password with a minimum of 6 and maximum of 18 character";
    }
    if(!newpassword){
        newpasswordError = "Please enter New Password";
    }
    return newpasswordError;
}

export const confPasswordValidate = (confpassword,newpassword) =>{
    let confpasswordError='';
    if(!confpassword){
        confpasswordError = "Please enter Confirm Password";
    }
    if(confpassword !== newpassword){
        confpasswordError = "The passwords you entered do not match";
    }
    return confpasswordError;
}

export const passwordValidate = (password) =>{
    let passwordError='';
    if(password.length<6 || password.length>18){
        passwordError = "Please enter Password min 6 and max 18 character";
    }
    if(!password){
        passwordError = "Please enter Password";
    }
    return passwordError;
}

export const genderValidate = (gender) =>{
    let genderError='';
    if(!gender){
        genderError = "Please select Gender";
    }
    return genderError;
}

export const stateValidate = (state) =>{
    let stateError='';
    if(!state){
        stateError = "Please select State";
    }
    return stateError;
}

export const cityValidate = (city) =>{
    let cityError='';
    if(!city){
        cityError = "Please enter City";
    }
    return cityError;
}
