// import React, { Component } from 'react';   
// import { bindActionCreators } from 'redux';
// import { withRouter } from 'react-router-dom';
// import connect from 'react-redux/es/connect/connect';
// import '../../../node_modules/simplebar/dist/simplebar.css';
// import '../../../node_modules/toasted-notes/src/styles.css'; 

// class FooterComponent extends Component{
//     constructor(props) {
//         super(props);
//         this.state = {
//         }
       
//     }

//     render(){
       
//         return(

//             <div className="footer">
//                  Copyright © 2019. Connect 5. All rights reserved
//             </div>
//         )
//     }
// }

// function mapDispatchToProps(dispatch){
//     return bindActionCreators({
//     }, dispatch)
// }

// function mapStateToProps({changePassword}) { 
//     return {
//     }
// }

// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FooterComponent));

import React from 'react';   

const FooterComponent = () => (
    <div className="footer">
                 {'Copyright © 2019. Connect 5. All rights reserved'}
            </div>
)

export default FooterComponent
