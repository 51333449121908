import React, { Component } from 'react';   
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import * as Actions from '../../store/actions';
import history from './../../history';
import HeaderPage from './../common/HeaderPage';
import FooterPage from './../common/FooterPage';
import proimg from './../../images/profile-page-img.jpg';
import * as config from './../../config';
import Message from './../common/Messages';

const agency_list = [
    {value:"School Professional",name:"School Professional"},
    {value:"Agency Professional",name:"Agency Professional"},
    {value:"Healthcare Professional",name:"Healthcare Professional"},
    {value:"Parent or Guardian",name:"Parent or Guardian"},
    {value:"Student",name:"Student"},
    {value:"Agency Volunteer",name:"Agency Volunteer"},
    {value:"Others",name:"Others"},
];

class ProfileComponent extends Component{
    
    constructor(props) {
        super(props);
        this.state = {
            id:'',
            nickname:'',
            gender:'',
            email:'',
            nicknameError:'', 
            genderError:'', 
            emailError:'', 
            cityError:'',
            stateError:'',
            editable:false,
            readOnly: true,
            showMessage:false,
            messageData:'',
            states:[],
            agency_name:'',
            agency_type:'',
            others:'',
            agency_type_disable:true,
            agency_type_data:''
        }
        this.access_token = localStorage.getItem('access_token');
        this.props.getState()
    }

    componentDidMount()
    { 
            const userdata = JSON.parse(localStorage.getItem('userdata'));
            let agency_data = 0;

            agency_list.map((data)=>{
                if(data.value === userdata.agency_type)
                {
                    agency_data = 1;
                }
            });            
            
            if(userdata){
                this.setState({
                    id:userdata.id,
                    email:userdata.email,
                    nickname:userdata.nickname,
                    gender:userdata.gender,
                    state:userdata.state,
                    city:userdata.city,
                    agency_name:userdata.agency_name,
                    agency_type:agency_data === 0 ? "Others" : userdata.agency_type,
                    others:agency_data === 0 ? userdata.agency_type : '',
                    agency_type_data:userdata.agency_type,
                    agency_type_disable:agency_data === 0 ? false : true

                })
            }
    }     

    OnPropertyChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value});
    }

    validate = () => {
        let nicknameError='';
        let genderError='';
        let cityError='';
        let stateError='';
        const charPattern = /^[a-zA-Z]+$/;
       
        
        if(!charPattern.test(this.state.nickname)){
            nicknameError = "Please enter valid Nickname";
        }
        if(!this.state.gender){
            genderError = "Please select Gender";
        }
        if(!this.state.state){
            stateError = "Please select State";
        }

        if(!this.state.city){
            cityError = "Please enter City";
        }


        if(nicknameError || genderError || stateError ||  cityError){
            this.setState({ nicknameError, genderError, stateError, cityError});
            return false;
        }

        return true;
    }

    onClickSubmit = () => { 
        const isValid = this.validate();
        if(isValid){
            var user = {
                id:this.state.id,
                nickname:this.state.nickname,
                gender:this.state.gender,
                state:this.state.state,
                city:this.state.city,
                agency_name:this.state.agency_name,
                agency_type:this.state.others === "" ? this.state.agency_type : this.state.others
            };
           //console.log("user : ", user)
           this.props.edit(user, this.access_token)
        }
    }

    onClickEdit = () =>{
        this.setState({editable:true, readOnly:false});

        let agency_data = 0;

        agency_list.map((data)=>{
            if(data.value === this.state.agency_type_data)
            {
                agency_data = 1;
            }
        });
        this.setState({
            agency_type:'',
            others:'',
            agency_type_data:'',
            agency_type:agency_data === 0 ? "Others" : this.state.agency_type_data,
            others:agency_data === 0 ? this.state.agency_type_data : '',
            agency_type_data:this.state.agency_type_data,
            agency_type_disable:agency_data === 0 || this.state.agency_type_data === "Others" ? false : true,
        })

    }

    componentWillReceiveProps = (nextProps) =>{ 
        const editdata = nextProps.editUser.editdata; 
        const states = nextProps.editUser.statedata;
        if(states){
            this.setState({states:states.states})
        }
        if(editdata){
            localStorage.setItem('userdata', JSON.stringify(editdata));
            this.setState({showMessage:true,
                        messageData:{type:'success',message:'Profile updated successfully'},
                        editable:false, 
                        readOnly:true,
                        nicknameError:'', 
                        genderError:'', 
                        emailError:'', 
                        cityError:'',
                        stateError:''});
        }
    }

    onClickCancel = () => {
       //get usertype from localStorage
        const userType = localStorage.getItem("usertype");
        const path  = config.userTypeRoutes[userType]
        if (path) {
            history.push(path.basePath)
        }
    }

    onClickEditCancel =() =>{
        const userdata = JSON.parse(localStorage.getItem('userdata'));
           
        this.setState({
            editable:false, 
            readOnly:true,
            nicknameError:'', 
            genderError:'', 
            emailError:'', 
            cityError:'',
            stateError:'',
            id:userdata.id,
            email:userdata.email,
            nickname:userdata.nickname,
            gender:userdata.gender,
            state:userdata.state,
            city:userdata.city
        })
    }

    closeShowMessage = () =>{
        this.setState({showMessage: false});
        window.location.reload()}
    Onagency_typeChange = (e) =>{
        this.setState({agency_type:e.target.value},()=>{
            if(this.state.agency_type === "Others")
            {
                this.setState({agency_type_disable:false},() =>{
                    
                })
            }
            else{
                this.setState({others:''})
                this.setState({agency_type_disable:true},() =>{})
            }
        });
    }



    render(){
        const {nickname,email,gender,state,city,agency_name,agency_type,agency_type_data} = this.state;

        return(
            <div>
                <HeaderPage/>   
                <div className={this.state.editable ? 'container container-reg' :'container container-reg container-profile'}>
                    <div className="row">
                        <div className="col-xl-7 col-md-5 proimg-hold">  {this.state.editable ? <h2 className="pagetitle">Edit Profile</h2> : <h2 className="pagetitle">Profile</h2> }<img src={proimg }  className="proimg" alt="Profile_Img"/></div>
                            <div className="col-xl-4 col-md-7 editlabel ">
                                <div className="row">
                                    <div className="col-xl-12">
                                            <div className="form-group">
                                                <label htmlFor="uname "> Email Address</label>
                                                <input type="text" className={ this.state.nameError ? 'form-control errorborder'  : 'form-control' } value={email}
                                                    name="email"  onChange={this.OnPropertyChange} readOnly/>
                                                <div style={{color:'red'}} className="reg-error" >{this.state.nameError}</div>
                                            </div>
                                    </div>
                                    <div className="col-xl-12">
                                                <div className="form-group">
                                                    <label htmlFor="nickname "> Nickname</label>
                                                    <input type="text" className={ this.state.nicknameError ? 'form-control errorborder'  : 'form-control' } value={nickname}
                                                        name="nickname"  onChange={this.OnPropertyChange} readOnly={this.state.readOnly}/>
                                                    <div style={{color:'red'}} className="reg-error" >{this.state.nicknameError}</div>
                                                </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12">
                                                <div className="form-group">
                                                    <label htmlFor="gender "> Gender</label>
                                                    {this.state.readOnly ? <input type="text" className='form-control' value={gender==='M'?'Male':gender==='F'?'Female':gender==='O'?'Other':''}  name="gender"  readOnly={true}/>
                                                        : <select className={ this.state.genderError ? 'form-control errorborder'  : 'form-control' } name="gender" value={gender} onChange={this.OnPropertyChange}>
                                                            <option value="">Select</option>
                                                            <option value="M">Male</option>
                                                            <option value="F">Female</option>
                                                            <option value="O">Other</option>
                                                        </select> }
                                                    <div style={{color:'red'}} className="reg-error" >{this.state.genderError}</div>
                                                </div>
                                    </div>
                                    <div className="col-xl-12">
                                                <div className="form-group">
                                                    <label htmlFor="state "> State</label>
                                                    {this.state.readOnly ? <input type="text" className='form-control' value={state} name="state"  readOnly={true}/>
                                                        :<select className={ this.state.stateError ? 'form-control errorborder'  : 'form-control' } name="state" value={state} onChange={this.OnPropertyChange}>
                                                                <option value="">Select</option>
                                                                {this.state.states.map(state =>{ return <option value={state.name}>{state.name}</option>})}
                                                        </select>}
                                                    <div style={{color:'red'}} className="reg-error" >{this.state.stateError}</div>
                                                </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xl-12">
                                                <div className="form-group">
                                                    <label htmlFor="city "> City</label>
                                                    <input type="text" className={ this.state.cityError ? 'form-control errorborder'  : 'form-control' } value={city}
                                                        name="city"  onChange={this.OnPropertyChange} readOnly={this.state.readOnly}/>
                                                    <div style={{color:'red'}} className="reg-error" >{this.state.cityError}</div>
                                                </div>
                                    </div>
                                </div>

                                <div className="row"> 
                                    <div className="col-xl-12">
                                        <div className="form-group">
                                            <label htmlFor="agency_name">Organization/School Name</label>
                                            <input type="text" className= 'form-control'   value = {this.state.agency_name} readOnly={this.state.readOnly}
                                                name="agency_name"  onChange={this.OnPropertyChange}/>
                                         <div style={{color:'red'}} className="reg-error" ></div>

                                        </div>
                                    </div>
                                </div> 

                                <div className="row"> 
                                    <div className="col-xl-12">
                                        <div className="form-group">
                                            <label htmlFor="profession">Profession</label>
                                            {this.state.readOnly ?<input type="text" className='form-control' value={agency_type_data} name="state"  readOnly={true}/>:
                                            <select className='form-control'  name="agency_type" onChange={this.Onagency_typeChange} value={agency_type}>
                                                    <option value="">Select</option>
                                                    {agency_list.map((data)=>(
                                                        <option value={data.value}>{data.name}</option>
                                                    ))
                                                    }
                                                    
                                            </select>}
                                        </div>
                                        <div style={{color:'red'}} className="reg-error" ></div>

                                    </div>
                                    </div>
                                    <div className="row"> 
                                   {!this.state.readOnly ? <div className="col-xl-12">
                                        <div className="form-group">
                                            <label htmlFor="others"> Please Specify</label>
                                            <input type="text" className='form-control' value={this.state.others}
                                                name="others"  onChange={this.OnPropertyChange} disabled={this.state.agency_type_disable}/>
                                         <div style={{color:'red'}} className="reg-error" ></div>

                                        </div>
                                    </div> : ''}
                                </div>
                                <br/>
                                <div className="row">
                                    <div className="col-xl-12">
                                        
                                                {this.state.editable ?
                                                        <div className="row">
                                                            <div className="col-xl-6 col-6">   <input type="button"
                                                                    value="CANCEL"
                                                                    className="btn btn-outline-secondary reg-cancel "
                                                                    onClick={this.onClickEditCancel} />
                                                            </div>
                                                            <div className="col-xl-6 col-6">
                                                                <input type="button"
                                                                    value="SAVE"
                                                                    className="btn btn-primary reg-submit"
                                                                    onClick={this.onClickSubmit} /> 
                                                            </div>
                                                        </div>
                                                        : 
                                                        <div className="row">
                                                            <div className="col-xl-6 col-6">   <input type="button"
                                                                    value="BACK"
                                                                    className="btn btn-outline-secondary reg-cancel "
                                                                    onClick={this.onClickCancel} />
                                                            </div>
                                                            <div className="col-xl-6 col-6">
                                                                <input type="button"
                                                                    value="EDIT"
                                                                    className="btn btn-primary reg-submit"
                                                                    onClick={this.onClickEdit} />
                                                            </div>
                                                        </div>
                                                }
                                        </div>
                                    
                                </div>
                            </div>

                    </div>

                </div>
                <FooterPage/>

                {/*open message modal */} 
                {
                    this.state.showMessage ? <Message
                        msgIsOpen = {this.state.showMessage}
                        msgIsClose = {this.closeShowMessage}
                        messageData = {this.state.messageData}
                    ></Message> :''
                }
            </div>

        )
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        edit:Actions.edit,
        getState:Actions.getState
    }, dispatch)
}

function mapStateToProps({registerUser}) { 
    return {
        editUser:registerUser
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileComponent));

