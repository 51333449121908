import React, { Component } from 'react';   
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import * as config from './../../../config';
import {scoid} from './../../../services/scormServices'
import IframeComm from "react-iframe-comm";

const sco_object = {};

const url = config.mediaPath['scormindex'];

const attributes = {
    src: url.filePath+"index.html",
    width: window.innerWidth,
    height: window.innerHeight,
    frameBorder: 0,
};

class MycourseComponent extends Component{
    constructor(props) {
        super(props);
        this.state = {
            course:'',
            scoObject:'',
            visible : false
        }
    }

    async componentDidMount() {
        document.body.classList.toggle('scormbody', true)
        this.access_token = localStorage.getItem('access_token');
        const userdata = localStorage.getItem('userdata');
        const user = JSON.parse(userdata);
        const scormpath = config.mediaPath['scormpath'];
        
        var data ={
            course_id:this.props.Course.id
        }
        let sco_id = await scoid(data, this.access_token);
        //console.log("Course :", this.props.Course)
        sco_object['sco_item_base']= scormpath.filePath;
        if(this.props.Course.course_url){
            sco_object['sco_item_url']=scormpath.filePath+this.props.Course.base_folder+"/"+this.props.Course.course_url;
              //sco_object['sco_item_url']='tka/index_lms_html5.html';
        }
        else{
            alert("Something went wrong! we are not able to find scorm content");
            return false;
        }
        sco_object['sco_callback_url']='http://34.203.217.49:8080/api/auth/update_scormdata';
        sco_object['sco_id']=sco_id;
        sco_object['accessToken']=this.access_token;
        sco_object['courseId']=this.props.Course.id;
        
        const sco_obj = {}
        sco_obj['student.course.sco.id']=sco_id;
        sco_obj['cmi.core.student_id']=user.id;
        sco_obj['cmi.core.student_name']=user.nickname;
        sco_object['sco_obj']=sco_obj;
        this.setState({scoObject: sco_object})
    }

    componentWillUnmount() {
        document.body.classList.remove('scormbody')
    }

    closeModal = () =>{
        this.props.modalIsClose();
    }

    render(){
        
        return(
            <div>
                <div className="course-container">
                        {this.state.scoObject ?
                            <IframeComm
                                attributes={attributes}
                                postMessageData={this.state.scoObject}
                            />
                        :''}
                    </div>
                
            </div>
        )
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
    }, dispatch)
}

function mapStateToProps({mycourse}) { 
    return {
      mycourse:mycourse
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MycourseComponent));

