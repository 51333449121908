import React, { Component } from 'react';   
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import '../../../../node_modules/simplebar/dist/simplebar.css'
import '../../../../node_modules/toasted-notes/src/styles.css'; 
import HeaderPage from './../../common/HeaderPage';
import FooterPage from './../../common/FooterPage';
import * as Actions from '../../../store/actions';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import ShowCourse from './ShowCourse';
import * as config from './../../../config';

class ParentcourseComponent extends Component{
    constructor(props) {
        super(props);
        this.state = {
          search:'',
          professionalCourses:[],
          professionalInProCourses:[],
          searchText:'',
          showCourseList:true,
          course:'',
          showProgressbar:true,
          tabClick:'allCourse',
        }
        this.access_token = localStorage.getItem('access_token');
        this.usertype = localStorage.getItem("usertype");
    }

    componentDidMount() 
    { 
        this.getCourses() 
    }

    getCourses = () =>{
        if(this.access_token && this.usertype){
            this.props.getCourses(this.access_token, {
                'user_type': this.usertype
            })
        }
    }

    componentWillReceiveProps = (nextProps) => { 
        const result = nextProps.mycourse;
        let serValue = this.state.searchText;
        let data = serValue ? serValue : '';
        
        if(result.success===true)
        {   
          if(result.mycoursedata.status_code===200)
          { 
            const inprogress=[];
            result.mycoursedata.courses.forEach(course => { 
                if(course.course_status===2){
                    inprogress.push(course);
                }
            });

            var filterData = result.mycoursedata.courses.filter(function (course) {
                return (JSON.stringify(course).toLowerCase().search(data.toLowerCase()) !== -1)
            })

            var filterInProgressData = inprogress.filter(function (course) {
                return (JSON.stringify(course).toLowerCase().search(data.toLowerCase()) !== -1)
            })
            
            this.setState({professionalCourses:filterData, professionalInProCourses:filterInProgressData, showProgressbar:false})
          }
        }
    }

    OnPropertyChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value});
    }

    onClickStartcourse =(course) =>{
        this.setState({showCourseList:false, course:course})
    }

    onClickAll = () =>{
        this.setState({tabClick:'allCourse'})
    }

    onClickInprogress = () =>{
        this.setState({tabClick:'inProgressCourse'})
    }

    

    render(){
        const imgurl = config.mediaPath['courseimage'];
        
        const { searchText } = this.state;

        return(
            
            <div className="parents">
            {this.state.showCourseList ? 
                <div>
                <HeaderPage/>
                    <div className="container course-container">

                            <div className="positionfixed"></div>
                            <div className="tabSearch"> <input type="text" className="form-control" value={searchText}  name="searchText" onChange={this.OnPropertyChange} onKeyPress={event => { if (event.key === 'Enter') { this.getCourses() } }} /><i className="la la-search" onClick={this.getCourses} style={{ cursor: 'pointer' }}></i>
                            </div>
                            <Tabs>    
                                <TabList>
                                    <Tab onClick={this.onClickAll}>All Courses</Tab>
                                    <Tab onClick={this.onClickInprogress}>In Progress Courses </Tab>
                                </TabList>
                                
                                <TabPanel>
                                    {this.state.showProgressbar ? <div className="loader" style={{left:'50%'}}>Loading...</div> :
                                        this.state.professionalCourses.length>0 ? 
                                            this.state.professionalCourses.map(course =>{  
                                                return( 
                                                    <div key={course.id}> 
                                                        <div className="row">
                                                            <div className="col-xl-3 col-md-3">
                                                                <img src={imgurl.filePath+course.course_image}  className="courseimg" alt="courseimg"/>
                                                            </div>
                                                            <div className="col-xl-5 col-md-5 course-dec">
                                                                <span className="course-title">{course.course_name}</span>
                                                                {course.course_description}
                                                            </div>
                                                            <div className="col-xl-4 col-sm-4 startcourse-hold ">
                                                                <button type="button" className="btn btn-primary startcourse" onClick={()=>{this.onClickStartcourse(course)}}>Start Course</button>
                                                            </div>
                                                        </div> 
                                                                        
                                                        <div className="row">
                                                            <div className="col-xl-12 separator " ></div>
                                                        </div>

                                                    </div>
                                                    )
                                            }):<div>
                                                <div className="row ">
                                                <span className="nofound">No result found</span>
                                                </div> 
                                            </div>
                                    }
                                </TabPanel>
                                <TabPanel>
                                    {this.state.showProgressbar ? <div className="loader">Loading...</div> :
                                        this.state.professionalInProCourses.length>0 ? 
                                        this.state.professionalInProCourses.map(course =>{  
                                            return( 
                                                <div key={course.id}> 
                                                    <div className="row">
                                                        <div className="col-xl-3 col-md-3">
                                                            <img src={imgurl.filePath+course.course_image}  className="courseimg" alt="courseimg"/>
                                                        </div>
                                                        <div className="col-xl-5 col-md-5 course-dec">
                                                            <span className="course-title">{course.course_name}</span>
                                                            {course.course_description}
                                                        </div>
                                                        <div className="col-xl-4 col-sm-4 startcourse-hold ">
                                                            <button type="button" className="btn btn-primary startcourse" onClick={()=>{this.onClickStartcourse(course)}}>Start Course</button>
                                                        </div>
                                                    </div> 
                                                                    
                                                    <div className="row">
                                                        <div className="col-xl-12 separator " ></div>
                                                    </div>

                                                </div>
                                                )
                                           
                                        }):<div>
                                            <div className="row ">
                                            <span className="nofound">No result found</span>
                                            </div> 
                                        </div>
                                    }
                                </TabPanel>
                            </Tabs>
                    </div>    
                <FooterPage/> 
            </div>
            :<ShowCourse
                 Course={this.state.course}                   
                ></ShowCourse>}
          </div>
          
        )
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
      getCourses:Actions.getCourses
    }, dispatch)
}

function mapStateToProps({mycourse}) { 
    return {
      mycourse:mycourse
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ParentcourseComponent));

