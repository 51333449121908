import React, { Component } from 'react';  
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import Modal from 'react-awesome-modal';

class HelpComponent extends Component{
    constructor(props) {
        super(props);
        this.state = {
            visible : false,
        };
    }

    closeModal() {
        this.props.helpIsClose();
    }
   

    render() {
        
        return ( 
          <div >
            <Modal 
                visible={this.props.helpIsOpen} 
                width="400" 
                effect="fadeInDown"
            >
                <div>
                <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Help</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={ event => this.closeModal()}>&times;</button>
                        </div>
                        
                        <div className="modal-body">
                            <div className="col-xl-12">
                               For further help, please contact Administrator.
                            </div>
                        </div>
                        
                        <div className="modal-footer">
                            <input type="button" className="btn btn-outline-secondary margin-btn-left " value="CANCEL" onClick={ event => this.closeModal()}/>
                        </div>
                    </div>
                </div>
            </Modal>
          </div>
        );
      }
}


function mapStateToProps ({message}) {
    return {
        
    }
}

export default withRouter(connect(mapStateToProps, null)(HelpComponent));

