import * as Actions from '../actions';
//import * as ChangePassActions from '../actions/changepass';

const initialState = {
    success: false,
    message:'',
    notificationdata:'',
    notificationcount:''
};

const changePassword = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.CHANGEPASS_SUCCESS:
        {
            return {
                success: true,
                message  : action.payload.message
            };
        }
        case Actions.CHANGEPASS_ERROR:
        {
            return {
                success: false,
                message  : action.payload.message
            };
        }
        case Actions.NOTIFICATION_SUCCESS:
        {
            return {
                ...state,
                success: true,
                notificationdata  : action.payload
            };
        }
        case Actions.NOTIFICATIONCOUNT_SUCCESS:
        {
            return {
                ...state,
                success: true,
                notificationcount  : action.payload
            };
        }
        default:
        {
            return state
        }
    }
};

export default changePassword;