import history from './../../history';
import * as config from './../../config.js';
import {userLogin} from './../../services/services';

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export function login(user){ 
    return (dispatch) =>
        userLogin(user).then((response)=>{  
            if(response.error){
                return dispatch({
                            type   : LOGIN_ERROR,
                            payload: response.error
                        });
            }
            else if(response.data){
                if(response.data.status_code===200){  
                        //if login success then store access_token & userdata in localStorage
                        localStorage.setItem('access_token', response.data.token);
                        localStorage.setItem('userdata', JSON.stringify(response.data.userdata));
                        
                        //get usertype from localStorage
                        const userType = localStorage.getItem("usertype");
                        //console.log("userType :", userType)

                        const path  = config.userTypeRoutes[userType]
                        if (path) {
                            history.push(path.basePath)
                        }

                        return dispatch({
                            type   : LOGIN_SUCCESS,
                            payload: response.data,
                        });
                    }
            }
           
        })
}