import * as Actions from '../actions';

const initialState = {
    success: false,
    logindata:'',
    forgotpassdata:''
};

const loginUser = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.LOGIN_SUCCESS:
        {
            return {
                ...state,
                success: true,
                logindata  : action.payload
            };
        }
        case Actions.LOGIN_ERROR:
        {
            return {
                ...state,
                success: false,
                logindata  : action.payload
            };
        }
        case Actions.FORGOTPASS_SUCCESS:
        {
            return {
                ...state,
                success: true,
                forgotpassdata  : action.payload
            };
        }
        case Actions.FORGOTPASS_ERROR:
        {
            return {
                ...state,
                success: false,
                forgotpassdata  : action.payload
            };
        }
        default:
        {
            return state
        }
    }
};

export default loginUser;