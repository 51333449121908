//export const API_URL= "http://34.203.217.49:8080"; 
// export const API_URL= "http://34.224.92.142:8080";
export const API_URL= "https://admin.caroneducation.com";
export const userTypeRoutes = {
    'youth': {
        basePath: '/youth',
        registerPath: '/register/youth',
        loginPath:'/login/youth'
    },
    'parents': {
        basePath: '/parents',
        registerPath: '/register/parents',
        loginPath:'/login/parents'
    },
    'professionals': {
        basePath: '/professionals',
        registerPath: '/register/professionals',
        loginPath:'/login/professionals'
    }
}

export const mediaPath = {
    'image': {
        filePath:'https://admin.caroneducation.com/storage/notifications/'
    },
    'video': {
        filePath:'https://admin.caroneducation.com/storage/notifications/'
    },
    'courseimage': {
        filePath:'https://admin.caroneducation.com/storage/courses/'
    },
    'scormpath': {
        filePath:'https://admin.caroneducation.com/storage/courses/'
    },
    'scormindex': {
        filePath:'https://admin.caroneducation.com/scorm/'
    }
}