import {courses, refreshToken} from './../../services/services';

export const MYCOURSE_SUCCESS = 'MYCOURSE_SUCCESS';

export function getCourses(access_token, user_type){ 
    return (dispatch) =>
        courses(access_token, user_type).then((response)=>{
                if(response.error){
                    if(response.error.status_code===401){
                        refreshToken().then((res)=>{
                            return courses(res.token, user_type)
                        }).then((resp) => {    
                            if(resp.data){
                                return dispatch({
                                    type   : MYCOURSE_SUCCESS,
                                    payload: resp.data,
                                });
                            }
                        })
                    }
                }
                if(response.data){
                    return dispatch({
                        type   : MYCOURSE_SUCCESS,
                        payload: response.data,
                    });
                }
        })
}


